<template>
  <div>
    <modal className="w-11/12 md:w-443px lg:w-443px" ref="addIndustryModal">
      <h1 class="text-2xl text-left font-bold mb-8">
        Add Industry
      </h1>
      <form @submit.prevent="submit">
        <template v-if="getFormError(form)">
          <div class="alert alert-red-soft mb-10">
            <span class="alert-icon">!</span>
            <span>{{ getFormError(form) }}</span>
          </div>
        </template>

        <form-group
          name="name"
          :form="form"
          v-model="form.data.industry_name.value"
        >
          Industry
        </form-group>

        <div class="mb-5">
          <h5 class="text-10 mb-2 text-gray-500">Image</h5>
          <dropzone
            class="mb-0 mr-2 w-full"
            accept=".png,.jpg,.jpeg"
            v-model="form.data.image.value"
          />
        </div>
        <div class="flex flex-row items-center">
          <checkbox v-model="form.data.featured.value" name="featured" />
          <label for="featured" class="text-sm ml-1">
            Add As Featured Category
          </label>
        </div>
        <div class="mt-5">
          <button
            class="btn btn-blue w-full"
            type="submit"
            :disabled="form.loading"
          >
            Submit
          </button>
        </div>
      </form>
    </modal>

    <modal
      className="w-11/12 md:w-2/5 xl:w-3/10 text-center pt-24"
      ref="successModal"
    >
      <img
        :src="assets.icons.lg.checkmark"
        class="mx-auto mb-8"
        height="90"
        alt="Check Mark"
      />

      <div class="text-lg font-bold mb-4">
        Success!
      </div>
    </modal>
  </div>
</template>
<script>
export default {
  data() {
    return {
      form: this.$options.basicForm([
        { name: "industry_name", rules: "required|aplpha" },
        { name: "image", value: null, rules: "nullable" },
        { name: "featured", rules: "nullable" }
      ])
    };
  },
  methods: {
    async submit() {
      if (!this.validateForm(this.form)) {
        return false;
      }
      this.form.loading = true;
      this.form.error = false;

      const image =
        this.form.data.image.value === null ? "" : this.form.data.image.value;

      const data = new FormData();
      data.append("industry_name", this.form.data.industry_name.value);
      data.append("image", image);
      data.append("featured", this.form.data.featured.value ? 1 : 0);

      await this.$post({
        url: `${this.$baseurl}/admin/merchants/industry`,
        headers: this.headers,
        data: data,
        success: () => {
          this.resetForm(this.form);
          this.$refs.addIndustryModal.close();
          this.$refs.successModal.open();
          this.$parent.getIndustries();
        },
        error: error => {
          this.form.error = error;
          this.mapFormErrors(this.form, error?.response?.data?.errors);
        }
      });
      this.form.loading = false;
    },
    open() {
      this.$refs.addIndustryModal.open();
    }
  }
};
</script>
